<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 px-1">
      <div class="document-header">
        <div class="conducted-box d-flex">
          <v-alert
              dense
              text
              class="mb-0 py-1"
              :color="conducted ? 'success' : 'secondary'"
              :type="conducted ? 'success' : 'warning'"
              style="flex: 1"
          >
            {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
          </v-alert>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 190px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required @inputValue="onDataChange"/>
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="person_id"
                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                :computed-getter="'get_personal_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Працівник"
                :show-select-on-focus="false"
                :required="true"
                :select-button-object="{
                                name: 'PERSONAL_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список працівників',
                                element_title: 'Працівник',
                                show: true
                              }"
                @selectChange="onPersonChange"
            />
          </div>
          <div style="flex: 1">
            <div v-if="!release_date && hire_date" class="person-status" style="height: 46px;">Дата прийняття:
              {{ hire_date | formatDate }}
            </div>
            <div v-if="release_date" class="person-status" style="height: 46px;">Дата звільнення:
              {{ release_date | formatDate }}
            </div>
          </div>
        </div>
      </div>
      <div class="document-body pa-3 pb-14">
        <v-tabs class="document-small-tabs" color="success">
          <v-tab class="text-left justify-start">
            Кадрові дані
          </v-tab>
          <v-tab class="text-left justify-start">
            Нарахування
          </v-tab>

          <v-tab-item class="px-0 py-2">
            <div class="pa-2">
              <v-btn small depressed color="success" @click="fillFromPrevious">
                Заповнити із попередніх
              </v-btn>
            </div>
            <div class="document-body-row pa-2">
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="old_subdivision_id"
                    :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                    :computed-getter="'get_subdivision_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Підрозділ (попередній)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'SUBDIVISION_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список підрозділ',
                                element_title: 'Підрозділ',
                                show: true
                              }"
                />
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="new_subdivision_id"
                    :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                    :computed-getter="'get_subdivision_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Підрозділ (новий)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'SUBDIVISION_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список підрозділ',
                                element_title: 'Підрозділ',
                                show: true
                              }"
                />
              </div>
            </div>

            <div class="document-body-row pa-2">
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="old_position_id"
                    :computed-action="'SELECT_POSITION_LIST_ELEMENTS'"
                    :computed-getter="'get_position_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Посада (попередня)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'POSITION_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список посад',
                                element_title: 'Посада',
                                show: true
                              }"
                />
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="new_position_id"
                    :computed-action="'SELECT_POSITION_LIST_ELEMENTS'"
                    :computed-getter="'get_position_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Посада (нова)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'POSITION_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список посад',
                                element_title: 'Посада',
                                show: true
                              }"
                />
              </div>
            </div>

            <div class="document-body-row pa-2">
              <div style="flex: 0 0 318px; margin-right: 16px">
                <number_input label="К-сть ставок (попередня)" v-model="old_rate_count" v-decimal/>
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <number_input label="К-сть ставок (новий)" v-model="new_rate_count" v-decimal/>
              </div>
            </div>

            <div class="document-body-row pa-2">
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="old_work_schedule_id"
                    :computed-action="'SELECT_WORK_SCHEDULE_LIST_ELEMENTS'"
                    :computed-getter="'get_work_schedule_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Графік роботи (попередній)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'WORK_SCHEDULE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список графіків роботи',
                                element_title: 'Графік роботи',
                                show: true
                              }"
                />
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="new_work_schedule_id"
                    :computed-action="'SELECT_WORK_SCHEDULE_LIST_ELEMENTS'"
                    :computed-getter="'get_work_schedule_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Графік роботи (новий)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'WORK_SCHEDULE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список графіків роботи',
                                element_title: 'Графік роботи',
                                show: true
                              }"
                />
              </div>
            </div>

            <div class="document-body-row pa-2">
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="old_chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Рахунок бух.обліку (попередній)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                />
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="new_chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Рахунок бух.обліку (новий)"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                />
              </div>
            </div>

          </v-tab-item>

          <v-tab-item class="pa-0 py-2">
            <div class="pa-2">
              <v-btn small depressed color="success" class="mr-2" @click.stop="addAccrual">
                Додати
              </v-btn>
              <v-btn small depressed color="error lighten-1" class="mr-2"
                     :disabled="!accrual_selected"
                     @click="removeAccrual"
              >
                Вилучити
              </v-btn>
            </div>
            <div class="document-body-row pa-2"
                 v-for="(accrual, idx) in accruals"
                 :key="`accrual-${accrual.row_idx}`"
            >
              <div style="flex: 0 0 30px; align-self: center; margin-top: 6px">
                {{ idx + 1 }}
              </div>
              <div style="flex: 0 0 50px; align-self: center">
                <v-checkbox v-model="accrual.selected" hide-details color="success" class="mt-0"/>
              </div>
              <div style="flex: 0 0 318px; margin-right: 16px">
                <select_input
                    v-model="accrual.accrual_id"
                    :computed-action="'SELECT_ACCRUAL_LIST_ELEMENTS'"
                    :computed-getter="'get_accrual_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="accrual.row_idx"
                    label="Вид нарахування"
                    :show-select-on-focus="false"
                    :select-button-object="{
                                name: 'ACCRUAL_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список видів нарахувань',
                                element_title: 'Вид нарахування',
                                show: true
                              }"
                    @selectChange="onAccrualIdChange"
                />
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px">
                <select_input
                    v-model="accrual.status"
                    :import-getter="{ name: 'work_transfer_accrual_type' }"
                    :input-value-as-value="false"
                    label="Дія: "
                    :show-select-on-focus="true"
                    clearable
                />
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px">
                <number_input label="Розмір" v-model="accrual.sum" v-decimal
                              :required="accrual.status !== 'end' && accrual.status !== 'unchanged'"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card-text>
    <v-card-actions style="position: absolute; bottom: 0; right: 0; width: 100%;">
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
  UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
  REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
  CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS, SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import workTransferAPI from "@/utils/axios/accounting/documents/work_transfer"
import {format_date_from_python, getDateFromString} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'work_transfer_accounting_modal_delete'

export default {
  name: "HWP_Modal_Work_Transfer",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
    number_input: () => import("@/components/accounting/input/document_number_input")
  },
  data() {
    return {
      hire_date: null,
      release_date: null,
      person_id: this.item.person_id || null,
      old_subdivision_id: this.item.old_subdivision_id || null,
      new_subdivision_id: this.item.new_subdivision_id || null,
      old_position_id: this.item.old_position_id || null,
      new_position_id: this.item.new_position_id || null,
      old_rate_count: this.item.old_rate_count,
      new_rate_count: this.item.new_rate_count,
      old_work_schedule_id: this.item.old_work_schedule_id || null,
      new_work_schedule_id: this.item.new_work_schedule_id,
      old_chart_of_account_id: this.item.old_chart_of_account_id || null,
      new_chart_of_account_id: this.item.new_chart_of_account_id,
      accruals: [],
    }
  },
  methods: {
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show = false, table = true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'old_subdivision_id': {id: 'old_subdivision_id', text: 'Не заповнений підрозділ (попередній)'},
        'new_subdivision_id': {id: 'new_subdivision_id', text: 'Не заповнений підрозділ'},
        'old_position_id': {id: 'old_position_id', text: 'Не заповнена посада (попередня)'},
        'new_position_id': {id: 'new_position_id', text: 'Не заповнена посада'},
        'old_rate_count': {id: 'old_rate_count', text: 'Не заповнена кількість ставок (попередня)'},
        'new_rate_count': {id: 'new_rate_count', text: 'Не заповнена кількість ставок'},
        'old_work_schedule_id': {id: 'new_rate_count', text: 'Не заповнений графік роботи (попередній)'},
        'new_work_schedule_id': {id: 'new_work_schedule_id', text: 'Не заповнений графік роботи'},
        'old_chart_of_account_id': {id: 'old_chart_of_account_id', text: 'Не заповнений рахунок бух.обліку (попередній)'},
        'new_chart_of_account_id': {id: 'new_chart_of_account_id', text: 'Не заповнений рахунок бух.обліку'},
      }

      const table_accruals = {
        'accrual_id': {id: 'accrual_id', table: 'Нарахування', text: 'Не заповнений вид нарахування'},
        'status': {id: 'status', table: 'Нарахування', text: 'Не заповнена дія'},
        'sum': {id: 'sum', table: 'Нарахування', text: 'Не заповнена сума'},
      }


      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: 'Дата документа має бути більшою за дату прийняття на роботу'}
          )
        }
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(table_accruals, 'Нарахування', 'accruals')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    addMaxAccrualRow() {
      return Math.max(...this.accruals.map(i => i.row_idx) || [0, 0])
    },
    addAccrual() {
      this.accruals.push({
        accrual_id: null,
        status: 'start',
        sum: 0,
        row_idx: this.addMaxAccrualRow() + 1,
        selected: false
      })
    },
    fillFromPrevious() {
      this.new_position_id = this.old_position_id
      this.new_subdivision_id = this.old_subdivision_id
      this.new_rate_count = this.old_rate_count
      this.new_work_schedule_id = this.old_work_schedule_id
      this.new_chart_of_account_id = this.old_chart_of_account_id
    },
    removeAccrual() {
      this.accruals.filter(i => i.selected).forEach(row => {
        this.accruals.splice(this.accruals.indexOf(row), 1)
      })
    },
    onDataChange() {
      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.$store.commit(
              ALERT_SHOW,
              {text: 'Дата документа має бути більшою за дату прийняття на роботу', color: 'secondary'})
        }
      }
    },
    onAccrualIdChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      const row_idx = (payload || {}).row_num || -1

      if (this.accruals.filter(i => i.row_idx !== row_idx && i.accrual_id === value).length) {
        const item = this.accruals.find(i => i.row_idx === row_idx)

        this.$nextTick(() => {
          item.accrual_id = null
        })
      }
    },
    onPersonChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      this.hire_date = (payload || {}).hire_date || (payload || {}).additional_field_1 || null
      this.release_date = (payload || {}).release_date || (payload || {}).additional_field_2 || null

      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.$store.commit(
              ALERT_SHOW,
              {text: 'Дата документа має бути більшою за дату прийняття на роботу', color: 'secondary'})
        }
      }


      if (value === null) {
        this.accruals = []
        this.old_work_schedule_id = null
        this.old_rate_count = 0
        this.old_position_id = null
        this.old_subdivision_id = null
        this.old_chart_of_account_id = null
      } else {
        workTransferAPI.previous_data({person_id: value})
            .then(response => response.data)
            .then(data => {
              if (data.length) {
                this.old_work_schedule_id = data[0].work_schedule_id
                this.old_rate_count = data[0].rate_count
                this.old_position_id = data[0].position_id
                this.old_subdivision_id = data[0].subdivision_id
                this.old_chart_of_account_id = data[0].chart_of_account_id
              }

              workTransferAPI.last_accrual({person_id: value})
                  .then(response => response.data)
                  .then(accrual_data => {
                    this.accruals = []
                    this.accruals = accrual_data.map((i, idx) => {
                      return {
                        accrual_id: i.accrual_id,
                        status: 'unchanged',
                        sum: i.sum,
                        row_idx: idx + 1,
                        selected: false
                      }
                    })
                  })
                  .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                  })
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'person_id': 'Працівник',
        'old_subdivision_id': 'Підрозділ (попередній)',
        'old_position_id': 'Посада (попередня)',
        'old_rate_count': 'К-сть ставок (попередня)',
        'old_work_schedule_id': 'Графік роботи (попередній)',
        'old_chart_of_account_id': 'Рахунок бух.обліку (попередній)',
      }

      let form_valid = true
      let errors = []
      let accrual_errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      this.accruals.forEach((accrual, idx) => {
        if (!accrual.accrual_id) {
          accrual_errors.push(`Нарахування: Рядок №${idx + 1}. Вид нарахування має бути заповнений`)
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n' + accrual_errors.join(', ')
      return {
        valid: form_valid,
        text: text
      }

    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.person_id = local_data.person_id || null
      this.old_subdivision_id = local_data.old_subdivision_id || null
      this.old_position_id = local_data.old_position_id || null
      this.old_rate_count = local_data.old_rate_count || null
      this.old_work_schedule_id = local_data.old_work_schedule_id || null
      this.old_chart_of_account_id = local_data.old_chart_of_account_id || null
      this.new_subdivision_id = local_data.new_subdivision_id || null
      this.new_position_id = local_data.new_position_id || null
      this.new_rate_count = local_data.new_rate_count || null
      this.new_work_schedule_id = local_data.new_work_schedule_id || null
      this.new_chart_of_account_id = local_data.new_chart_of_account_id || null
      this.accruals = []
    },
    fetch_data_by_id() {
      if (this.isNew) return

      workTransferAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.person_id = data.person_id || null
            this.old_subdivision_id = data.old_subdivision_id || null
            this.old_position_id = data.old_position_id || null
            this.old_rate_count = data.old_rate_count || null
            this.old_work_schedule_id = data.old_work_schedule_id || null
            this.old_chart_of_account_id = data.old_chart_of_account_id || null
            this.new_subdivision_id = data.new_subdivision_id || null
            this.new_position_id = data.new_position_id || null
            this.new_rate_count = data.new_rate_count || null
            this.new_work_schedule_id = data.new_work_schedule_id || null
            this.new_chart_of_account_id = data.new_chart_of_account_id || null
            this.accruals = data.accruals
          })
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    closeModal() {
      this.$emit('closeModal')
      this.hire_date = null
      this.release_date = null
      this.conducted = this.item.conducted
      this.date = this.item.date
      this.number = this.item.number
      this.person_id = this.item.person_id || null
      this.old_subdivision_id = this.item.old_subdivision_id || null
      this.new_subdivision_id = this.item.new_subdivision_id || null
      this.old_position_id = this.item.old_position_id || null
      this.new_position_id = this.item.new_position_id || null
      this.old_rate_count = this.item.old_rate_count
      this.new_rate_count = this.item.new_rate_count
      this.old_work_schedule_id = this.item.old_work_schedule_id || null
      this.new_work_schedule_id = this.item.new_work_schedule_id
      this.old_chart_of_account_id = this.item.old_chart_of_account_id || null
      this.new_chart_of_account_id = this.item.new_chart_of_account_id
      this.accruals = []
      this.itemId = this.item.id

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа переведення № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        person_id: this.person_id,
        old_subdivision_id: this.old_subdivision_id,
        old_position_id: this.old_position_id,
        old_work_schedule_id: this.old_work_schedule_id,
        old_rate_count: this.old_rate_count,
        old_chart_of_account_id: this.old_chart_of_account_id,
        new_subdivision_id: this.new_subdivision_id,
        new_position_id: this.new_position_id,
        new_work_schedule_id: this.new_work_schedule_id,
        new_rate_count: this.new_rate_count,
        new_chart_of_account_id: this.new_chart_of_account_id,
        rows: this.accruals.map(item => {
          return {status: item.status, accrual_id: item.accrual_id, sum: item.sum, date: this.date.substr(0, 10)}
        })
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: false,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  },
  computed: {
    col_width() {
      return (100 / this.days.length).toPrecision(4)
    },
    accrual_selected() {
      return !!this.accruals.filter(i => i.selected).length
    }
  }
}
</script>

<style scoped lang="scss">
.document-body-row {
  display: flex;
  flex-wrap: wrap;
}

.person-status {
  background-color: #eeeeee;
  font-size: .86rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 5px;
}
</style>